import { Type_RefFuncKrpano } from '@components/Krpano/types';
import { useCallback, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I_Group, I_Hotspot, I_Scene } from '@containers/Home/types';
import { useLeadFormCapture } from '@containers/Home/hook/useLeadFormCapture';
import { useTourEventState, useTourStats } from '../global/TourStats';
import {
  selectConfigAutoChangeScene,
  selectConfigAutoRotate,
  selectConfigMap,
  selectDetailFirstScene,
  selectDetailProject,
  selectDollhouseConfigGlobal,
  selectDollhouseProject,
  selectLanguagesProject,
  selectListFloorplan,
  selectListGroup,
  selectListScene,
  selectSceneSelected,
} from '../selectors';
import { actions } from '../slice';
import { separateColorAndTransparency } from '@utils/helper';
import { HOTSPOT_COLOR_DOLLHOUSE_DEFAULT } from '../constants';

interface Props {
  krpanoRef: Type_RefFuncKrpano | null;
}

export const useKrpano = ({ krpanoRef }: Props) => {
  const dispatch = useDispatch();
  const configAutoRotate = useSelector(selectConfigAutoRotate);
  const configAutoChangeScene = useSelector(selectConfigAutoChangeScene);
  const detailFirstScene = useSelector(selectDetailFirstScene);
  const detailProject = useSelector(selectDetailProject);
  const listGroup = useSelector(selectListGroup);
  const listScene = useSelector(selectListScene);
  const sceneSelected = useSelector(selectSceneSelected);
  const listFloorplan = useSelector(selectListFloorplan);
  const configMap = useSelector(selectConfigMap);
  const languagesProject = useSelector(selectLanguagesProject);
  const dollhouse = useSelector(selectDollhouseProject);
  const dollhouseConfigGlobal = useSelector(selectDollhouseConfigGlobal);

  const [, setTourStats] = useTourStats('stats');
  const [tourEventStats, setTourEventState] = useTourEventState('state');
  const { onCheckShowFormCaptureOnHotspot } = useLeadFormCapture({});
  const configAutoChangeSceneRef = useRef<typeof configAutoChangeScene>(null!);

  const isExistSound = useMemo(
    () =>
      sceneSelected?.hotspots?.some(
        item =>
          (item.type === 'sound' &&
            (item.config?.sound?.file || item.config?.sound?.url)) ||
          (item.type === 'instructor' && item.config?.instructor?.media) ||
          (item.type === 'media' &&
            item.config?.media?.type === 'media-video' &&
            item.config?.media?.media?.src),
      ) ||
      !!detailProject?.background_sound ||
      !!sceneSelected?.background_sound,
    [detailProject, sceneSelected?.background_sound, sceneSelected?.hotspots],
  );

  const isShowIconInfoPlace = useMemo(
    () => !!detailProject?.post,
    [detailProject],
  );

  const isShowIconInfoScene = useMemo(
    () => !!sceneSelected?.post,
    [sceneSelected?.post],
  );

  const isShowIconMap = useMemo(() => !!configMap?.isEnabled, [configMap]);

  const isShowIconFloorplan = useMemo(
    () => !!listFloorplan.length,
    [listFloorplan],
  );

  const isShowIconMultipleStaging = useMemo(
    () =>
      !!(
        sceneSelected?.mode === 'staging' &&
        sceneSelected?.staging?.length &&
        sceneSelected?.staging?.filter(item => item.media).length &&
        !!sceneSelected?.configStaging?.modeDisplay
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sceneSelected?.id],
  );

  const isShowIconSoundProject = useMemo(() => isExistSound, [isExistSound]);

  const isShowIconSoundScene = useMemo(
    () => !!sceneSelected?.background_sound,
    [sceneSelected],
  );

  const isShowIconDollhouse = useMemo(
    () => !!dollhouse?.obj_path,
    [dollhouse?.obj_path],
  );

  // NOTE: play change scene
  const isShowIconChangeScene = useMemo(
    () => configAutoChangeScene.enabled,
    [configAutoChangeScene],
  );
  const isChangeScene = useMemo(
    () => configAutoChangeScene.on,
    [configAutoChangeScene],
  );

  // NOTE: rotate
  const isShowIconRotate = useMemo(
    () => configAutoRotate.enabled,
    [configAutoRotate],
  );
  const isRotate = useMemo(() => configAutoRotate.on, [configAutoRotate]);
  const waitTimeAutoRotate = useMemo(
    () => configAutoRotate.waitTime,
    [configAutoRotate],
  );
  const speedAutoRotate = useMemo(
    () => configAutoRotate.speed,
    [configAutoRotate],
  );

  // NOTE: dollhouse
  const dollhouseXml = useMemo(() => {
    const hotspotColor = separateColorAndTransparency(
      dollhouseConfigGlobal?.hotspotColor || HOTSPOT_COLOR_DOLLHOUSE_DEFAULT,
    );
    return dollhouse
      ? `<scene name="dollhouse">
            <image>
              <depthmap encoding='rgb24' rendermode="3dmodel" scale="5" textured="true" url="${dollhouse?.obj_path}" />
            </image>
            ${listScene
              .filter(item => item.dollhouseData?.mesh)
              .reduce((result, item) => {
                result += `
                  <hotspot
                    name="hotspot_dollhouse_${
                      item.id
                    }" type="text" distorted="true" depth="0" depthbuffer="true" rotationorder="xzy"
                    width="40" height="40" bgcolor="${hotspotColor.color.replace(
                      '#',
                      '0x',
                    )}" roundedge="20"
                    tx="${item.dollhouseData?.tx}" ty="${item.dollhouseData
                      ?.ty}" tz="${item.dollhouseData
                      ?.tz}" rx="-90.0" ry="-0.0" rz="0.0"
                    onclick="set(is_dollhouse_view, false); tween(view.oz, 0.0, 1.75); set(control.invert,false);
                    if (global.customtransition != 'SKIP', if(global.customtransition !== null, global.customtransition();,
				              tween(view.tx|view.ty|view.tz, calc(${
                        item.dollhouseData.tx
                      } + '|' + ${item.dollhouseData.ty} + '|' + ${
                        item.dollhouseData.tz
                      }), 1.75, easeinoutsine););); delete(global.customtransition); delete(global.customtransitiontime);
	                  wait(1.25);
                    tween(layer[depthmap_walkinfo].alpha, 0.0, 0.25); removelayer('popup_close_x'); set(sceneId, '${
                      item.id
                    }'); events.dispatch('onnewpanovr');"
                    bgshadow="0 0 3 ${hotspotColor.color.replace(
                      '#',
                      '0x',
                    )} 0.5"
                    onover="tween(scale,1);"
                    onout="tween(scale,0.7);"
                    alpha="1" scale="0.7"
                  />`;
                return result;
              }, '')}
          </scene>`
      : null;
  }, [dollhouse, dollhouseConfigGlobal?.hotspotColor, listScene]);

  useEffect(() => {
    configAutoChangeSceneRef.current = configAutoChangeScene;
  }, [configAutoChangeScene]);

  const onClickHotspot = useCallback(
    ({ hotspot }) => onCheckShowFormCaptureOnHotspot(hotspot),
    [onCheckShowFormCaptureOnHotspot],
  );

  const onChangeAutoChangeScene = useCallback(
    on => {
      if (
        configAutoChangeSceneRef.current?.enabled &&
        configAutoChangeSceneRef.current?.on !== on
      )
        dispatch(actions.setConfigAutoChangeScene({ on }));
    },
    [dispatch],
  );

  const onToFirstScene = useCallback(() => {
    dispatch(actions.setSceneSelected(detailFirstScene));
  }, [detailFirstScene, dispatch]);

  const onChangeLanguageProject = useCallback(
    (id: string) => {
      const language = languagesProject.find(item => item.id === id);
      language && dispatch(actions.setLanguageProjectSelected(language));
    },
    [dispatch, languagesProject],
  );

  // NOTE: event krpano controlbar
  const onUp = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: 'top' }));
    krpanoRef?.toUp();
  }, [krpanoRef, setTourStats]);

  const onLeft = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: 'left' }));
    krpanoRef?.toLeft();
  }, [krpanoRef, setTourStats]);

  const onDown = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: 'bottom' }));
    krpanoRef?.toDown();
  }, [krpanoRef, setTourStats]);

  const onRight = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: 'right' }));
    krpanoRef?.toRight();
  }, [krpanoRef, setTourStats]);

  const onVR = useCallback(() => krpanoRef?.viewWebvr(), [krpanoRef]);

  const onViewNormal = useCallback(() => {
    krpanoRef?.viewNormal();
  }, [krpanoRef]);

  const onViewPlannet = useCallback(() => {
    krpanoRef?.viewPlanet();
  }, [krpanoRef]);

  const onViewMirrorBall = useCallback(() => {
    krpanoRef?.viewMirrorBall();
  }, [krpanoRef]);

  const onToggleGyro = useCallback(() => {
    krpanoRef?.toggleGyro();
  }, [krpanoRef]);

  const onRemoveAllChevronHotspot = useCallback(
    (hotspots: I_Hotspot[]) => krpanoRef?.removeAllChevronHotspot(hotspots),
    [krpanoRef],
  );

  const onStopRotate = useCallback(() => {
    dispatch(actions.setConfigAutoRotate({ on: false }));
    krpanoRef?.stopAutoRotate();
  }, [dispatch, krpanoRef]);

  const onStartRotate = useCallback(() => {
    dispatch(actions.setConfigAutoRotate({ on: true }));
    krpanoRef?.startAutoRotate({
      speed: speedAutoRotate,
      wait_time: waitTimeAutoRotate,
    });
  }, [dispatch, krpanoRef, speedAutoRotate, waitTimeAutoRotate]);

  const onToggleRotate = useCallback(() => {
    if (!isRotate) onStartRotate();
    else onStopRotate();
  }, [isRotate, onStartRotate, onStopRotate]);

  const onStopVertical = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: '' }));
    krpanoRef?.stopToVertical();
  }, [krpanoRef, setTourStats]);

  const onStopHorizontal = useCallback(() => {
    setTourStats(prev => ({ ...prev, btnDirectionActive: '' }));
    krpanoRef?.stopToHorizontal();
  }, [krpanoRef, setTourStats]);

  const onFullscreen = useCallback(() => {
    krpanoRef?.viewFullscreen();
  }, [krpanoRef]);

  const onTurnOnScreenshotCapture = useCallback(() => {
    krpanoRef?.call('show_snapshot(true)');
  }, [krpanoRef]);

  // NOTE: sound hotspot
  const onStartHotspotSound = useCallback(() => {
    krpanoRef?.triggerSound();
  }, [krpanoRef]);

  const onStopHotspotSound = useCallback(() => {
    krpanoRef?.destroySound();
  }, [krpanoRef]);

  const onUnmuteHotspotSound = useCallback(() => {
    sceneSelected && krpanoRef?.unmuteAllSound(sceneSelected);
  }, [krpanoRef, sceneSelected]);

  const onMuteHotspotSound = useCallback(() => {
    sceneSelected && krpanoRef?.muteAllSound(sceneSelected);
  }, [krpanoRef, sceneSelected]);

  // NOTE: sound scene
  const onSetSoundOnScene = useCallback(
    isSoundOnScene =>
      setTourEventState(prev => ({
        ...prev,
        isSoundOnScene,
      })),
    [setTourEventState],
  );

  // NOTE: mode multiple staging
  const onToggleModeMultipleStaging = useCallback(
    (active: boolean | undefined = undefined) => {
      setTourEventState(prev => ({
        ...prev,
        isActiveModeMultipleStaging:
          active === undefined ? !prev.isActiveModeMultipleStaging : active,
      }));
    },
    [setTourEventState],
  );

  const onTurnOnSoundScene = useCallback(
    () => onSetSoundOnScene(true),
    [onSetSoundOnScene],
  );

  const onTurnOffSoundScene = useCallback(
    () => onSetSoundOnScene(false),
    [onSetSoundOnScene],
  );

  const onToggleSoundScene = useCallback(() => {
    if (!tourEventStats.isSoundOnScene) onTurnOnSoundScene();
    else onTurnOffSoundScene();
  }, [onTurnOffSoundScene, onTurnOnSoundScene, tourEventStats.isSoundOnScene]);

  // NOTE: sound project
  const onTurnOnSoundProject = useCallback(() => {
    onUnmuteHotspotSound();
    dispatch(actions.setConfigSoundProject({ on: true }));
  }, [dispatch, onUnmuteHotspotSound]);

  const onTurnOffSoundProject = useCallback(() => {
    onMuteHotspotSound();
    dispatch(actions.setConfigSoundProject({ on: false }));
  }, [dispatch, onMuteHotspotSound]);

  // NOTE: info place tour
  const onOpenDialogPostProject = useCallback(() => {
    setTourEventState(prev => ({ ...prev, visibleDialogPostProject: true }));
  }, [setTourEventState]);

  const onCloseDialogPostProject = useCallback(() => {
    setTourEventState(prev => ({ ...prev, visibleDialogPostProject: false }));
  }, [setTourEventState]);

  const onShowInfoPlace = useCallback(() => {
    setTourEventState(prev => ({
      ...prev,
      idPostProjectSelected: detailProject?.post || '',
    }));
    onOpenDialogPostProject();
  }, [detailProject, onOpenDialogPostProject, setTourEventState]);

  // NOTE: info post scene
  const onOpenDialogPostScene = useCallback(() => {
    setTourEventState(prev => ({ ...prev, visibleDialogPostScene: true }));
  }, [setTourEventState]);

  const onCloseDialogPostScene = useCallback(() => {
    setTourEventState(prev => ({
      ...prev,
      visibleDialogPostScene: false,
    }));
  }, [setTourEventState]);

  const onToggleInfoScene = useCallback(
    (isVisible: boolean | undefined = undefined) => {
      if (sceneSelected?.post) {
        if (
          (isVisible !== undefined && isVisible) ||
          !tourEventStats.visibleDialogPostScene
        ) {
          setTourEventState(prev => ({
            ...prev,
            idPostSceneSelected: sceneSelected?.post || '',
          }));
          onOpenDialogPostScene();
        } else if (
          (isVisible !== undefined && !isVisible) ||
          tourEventStats.visibleDialogPostScene
        )
          onCloseDialogPostScene();
      } else onCloseDialogPostScene();
    },
    [
      tourEventStats,
      setTourEventState,
      sceneSelected?.post,
      onOpenDialogPostScene,
      onCloseDialogPostScene,
    ],
  );

  const onToggleFloorplan = useCallback(() => {
    setTourEventState(prev => ({
      ...prev,
      isVisibleFloorplan: !prev.isVisibleFloorplan,
    }));
  }, [setTourEventState]);

  const onToggleMap = useCallback(() => {
    setTourEventState(prev => ({
      ...prev,
      isVisibleMap: !prev.isVisibleMap,
    }));
  }, [setTourEventState]);

  const onToggleDollhouseView = useCallback(() => {
    setTourEventState(prev => ({
      ...prev,
      isVisibleDollhouse: krpanoRef?.get('is_dollhouse_view') ? false : true,
    }));
    krpanoRef?.set('sceneId', sceneSelected?.id);
    krpanoRef?.call(
      krpanoRef?.get('is_dollhouse_view')
        ? 'reset_view()'
        : 'dollhouse_view(false)',
    );
  }, [krpanoRef, sceneSelected?.id, setTourEventState]);

  // UTILS

  const onGenerateXMLMenuThumbnailOnVR = useCallback(() => {
    let xml = '<krpano thumbready="0">';

    function generateSceneXml(
      scene: I_Scene,
      group: I_Group | undefined = undefined,
    ) {
      return `<scene name="scene_${scene.id}" title="${scene.title
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll(`'`, '&apos;')
        .replaceAll('"', '&quot;')}" onstart="set(sceneId, '${
        scene.id
      }'); trace(${scene.id}); events.dispatch('onnewpanovr');" thumburl="${
        scene.media.src_thumb || scene.media.src_preview
      }" lat="" lng="" alt="" heading="" group="${
        group ? 'group_' + group?.id : ``
      }" nogrid="false"/>`;
    }

    if (listGroup.length === 1 && listGroup[0].id === 'uncategory') {
      listGroup[0].scenes.forEach(scene => {
        xml += generateSceneXml(scene);
      });
    } else {
      xml += ``;
      listGroup.forEach(group => {
        // xml += `<panoramagroup name="group_${group.id}" title="Title: ${group.name}" description="" mainscene="${group.scenes[0].id}" thumburl="${group.scenes[0].media?.src_thumb}" />`;
        group.scenes.forEach(scene => {
          xml += generateSceneXml(scene);
        });
      });
    }
    xml += '</krpano>';

    return xml;
  }, [listGroup]);

  return useMemo(
    () => ({
      onUp,
      onDown,
      onLeft,
      onRight,
      onVR,
      onToggleGyro,
      onViewNormal,
      onViewPlannet,
      onViewMirrorBall,
      onStopVertical,
      onStopHorizontal,
      onFullscreen,
      onToFirstScene,
      onTurnOnScreenshotCapture,
      onRemoveAllChevronHotspot,
      // multi-language project
      languagesProject,
      onChangeLanguageProject,
      // rotate
      isRotate,
      onStopRotate,
      onToggleRotate,
      onStartRotate,
      isShowIconRotate,
      //
      onClickHotspot,
      // auto change scene
      isChangeScene,
      isShowIconChangeScene,
      onChangeAutoChangeScene,
      // info tour
      onShowInfoPlace,
      onOpenDialogPostProject,
      onCloseDialogPostProject,
      isShowIconInfoPlace,
      // info tour
      onToggleInfoScene,
      onOpenDialogPostScene,
      onCloseDialogPostScene,
      isShowIconInfoScene,
      // sound
      isExistSound,
      // sound scene
      onSetSoundOnScene,
      onTurnOnSoundScene,
      onToggleSoundScene,
      onTurnOffSoundScene,
      isShowIconSoundScene,
      // sound hotspot
      onStartHotspotSound,
      onStopHotspotSound,
      // sound project
      onTurnOnSoundProject,
      onTurnOffSoundProject,
      isShowIconSoundProject,
      // map
      isShowIconMap,
      onToggleMap,
      // floorplan
      isShowIconFloorplan,
      onToggleFloorplan,
      // multiple staging
      isShowIconMultipleStaging,
      onToggleModeMultipleStaging,
      // dollhouse
      dollhouseXml,
      isShowIconDollhouse,
      onToggleDollhouseView,
      //
      onGenerateXMLMenuThumbnailOnVR,
    }),
    [
      onUp,
      onDown,
      onLeft,
      onRight,
      onVR,
      onToggleGyro,
      onViewNormal,
      onViewPlannet,
      onViewMirrorBall,
      onStopVertical,
      onStopHorizontal,
      onFullscreen,
      onToFirstScene,
      languagesProject,
      onChangeLanguageProject,
      onTurnOnScreenshotCapture,
      onRemoveAllChevronHotspot,
      isRotate,
      onStopRotate,
      onToggleRotate,
      onStartRotate,
      isShowIconRotate,
      onClickHotspot,
      isChangeScene,
      isShowIconChangeScene,
      onChangeAutoChangeScene,
      onShowInfoPlace,
      onOpenDialogPostProject,
      onCloseDialogPostProject,
      isShowIconInfoPlace,
      onToggleInfoScene,
      onOpenDialogPostScene,
      onCloseDialogPostScene,
      isShowIconInfoScene,
      isExistSound,
      onSetSoundOnScene,
      onTurnOnSoundScene,
      onToggleSoundScene,
      onTurnOffSoundScene,
      isShowIconSoundScene,
      onStartHotspotSound,
      onStopHotspotSound,
      onTurnOnSoundProject,
      onTurnOffSoundProject,
      isShowIconSoundProject,
      isShowIconMap,
      onToggleMap,
      isShowIconFloorplan,
      onToggleFloorplan,
      isShowIconMultipleStaging,
      onToggleModeMultipleStaging,
      dollhouseXml,
      isShowIconDollhouse,
      onToggleDollhouseView,
      onGenerateXMLMenuThumbnailOnVR,
    ],
  );
};
